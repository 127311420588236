import {
  ADD_REPORT,
  REMOVE_REPORT,
  SET_REPORT_DATES,
  SET_REPORT_SKILLGAMES,
  SET_REPORTS,
  SET_REPORT_PLAYERS
} from '../mutations.type'

const mutations = {
  [SET_REPORTS](state, reports) {
    state.reports = reports
  },

  [ADD_REPORT](state, report) {
    state.reports.unshift(report)
  },

  [REMOVE_REPORT](state, report) {
    state.reports = state.reports.filter((r) => r.id !== report.id)
  },

  [SET_REPORT_SKILLGAMES](state, skillgames) {
    state.reportSkillgames = skillgames
  },

  [SET_REPORT_DATES](state, dates) {
    state.reportDates = dates
  },

  [SET_REPORT_PLAYERS](state, players) {
    state.reportPlayers = players
  }
}

export default mutations
