import ApiService from '@/services/api.service'
import { download } from '@/services/file.service'
import {
  ADD_REPORT,
  REMOVE_REPORT,
  SET_ERROR,
  SET_REPORT_DATES,
  SET_REPORT_SKILLGAMES,
  SET_REPORTS,
  SET_REPORT_PLAYERS
} from '@/store/mutations.type'
import {
  CREATE_REPORT,
  DELETE_REPORT,
  DOWNLOAD_REPORT,
  FETCH_REPORT_DATES,
  FETCH_REPORT_SKILLGAMES,
  FETCH_REPORTS,
  FETCH_REPORT_PLAYERS
} from '@/store/actions.type'

const actions = {
  async [FETCH_REPORTS](context) {
    const teamId = context.rootGetters.getSelectedTeam.id

    await ApiService.get(`reports/teams/${teamId}`)
      .then(({ data }) => {
        context.commit(SET_REPORTS, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [DOWNLOAD_REPORT](context, report) {
    await ApiService.getWithConfig(`reports/${report.id}/download`, {
      responseType: 'blob'
    })
      .then(({ data }) => {
        download(new Blob([data]), `${report.name}.${report.fileType}`)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [CREATE_REPORT](context, { teamId, payload }) {
    await ApiService.post(`reports/teams/${teamId}`, payload)
      .then(({ data }) => {
        context.commit(ADD_REPORT, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [DELETE_REPORT](context, report) {
    await ApiService.delete(`reports/${report.id}`)
      .then(() => {
        context.commit(REMOVE_REPORT, report)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_REPORT_SKILLGAMES](context) {
    const teamId = context.rootGetters.getSelectedTeam.id

    await ApiService.get(`reports/teams/${teamId}/skillgames`)
      .then(({ data }) => {
        context.commit(SET_REPORT_SKILLGAMES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_REPORT_DATES](context) {
    const teamId = context.rootGetters.getSelectedTeam.id

    await ApiService.get(`reports/teams/${teamId}/dates`)
      .then(({ data }) => {
        context.commit(SET_REPORT_DATES, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_REPORT_PLAYERS](context, payload = {}) {
    const { page = 1, name = '', per_page = 999 } = payload
    const teamId = context.getters.getSelectedTeam.id
    try {
      const response = await ApiService.getWithParams(
        `/teams/${teamId}/users/players`,
        {
          page: page,
          per_page: per_page,
          name: name
        }
      )
      context.commit(SET_REPORT_PLAYERS, response.data.data)

      return response.data
    } catch (error) {
      console.error('Error:', error.response.data.message)
      context.commit(SET_ERROR, error.response.data.message)
    }
  }
}

export default actions
