// FOR APP
export const SET_ERROR = 'setError'
export const ERROR_CHECKED = 'errorChecked'

// FOR AUTH
export const SET_AUTH = 'setAuth'
export const SET_ROLES = 'setRoles'
export const PURGE_AUTH = 'purgeAuth'

// FOR DATA POLICIES
export const SET_DATA_POLICIES = 'setDataPolicies'
export const SET_USER_DATA_POLICIES = 'setUserDataPolicies'

//FOR USERS
export const SET_USER = 'setUser'
export const SET_FIELD_POSITIONS = 'setFieldPositions'
export const RESET_USERS = 'resetUsers'

// FOR RANKING
export const SET_PLAYER_SKILLGAME_RANKING = 'setPlayerSkillgameRanking'
export const SET_COUNTRY_SKILLGAME_RANKING = 'setCountrySkillgameRanking'
export const SET_TEAM_STENGTH_INDEX_RANKING = 'setTeamStrengthIndexRanking'
export const SET_TEAM_SKILLGAME_RANKING = 'setTeamSkillgameRanking'
export const SET_TEAM_BEST_RANKS = 'setTeamBestRanks'
export const SET_PLAYER_RANKS = 'setPlayerRanks'
export const SET_COUNTRIES = 'setCountries'
export const SET_POSITIONS = 'setPositions'

//FOR SKILLGAMES
export const SET_SKILLGAME = 'setSkillgame'
export const SET_SKILLGAMES = 'setSkillgames'

// FOR ABILITIES
export const SET_ABILITY_HISTORY = 'setAbilityHistory'
export const SET_AGE_SPECIFIC = 'setAgeSpecific'
export const BOOST_ABILITIES = 'boostAbilities' //hotfix

// FOR RESULTS
export const SET_PLAYER_SKILLGAME_RESULTS = 'setPlayerSkillgameResults'
export const SET_BEST_RESULTS = 'setBestResults'
export const SET_PLAYER_ALL_RESULTS = 'setPlayerAllResults'

// FOR TEAMS
export const SET_TEAMS = 'setTeams'
export const SET_SELECTED_TEAM = 'setSelectedTeam'
export const RESET_TEAMS = 'resetTeams'
export const SET_TEAM_PLAYERS = 'setTeamPlayers'
export const SET_ALL_TEAM_PLAYERS = 'setAllTeamPlayers'
export const SET_TEAM_TRAINERS = 'setTeamTrainers'
export const PURGE_TEAMS = 'purgeTeams'
export const ADD_NEW_TEAM = 'addNewTeam'
export const SET_UPDATED_TEAM = 'setUpdatedTeam'
export const REMOVE_TEAM = 'removeTeam'
export const ADD_NEW_PLAYER_TO_TEAM = 'addNewPlayerToTeam'
export const ADD_NEW_TRAINER_TO_TEAM = 'addNewTrainerToTeam'
export const ADD_TO_PLAYERS = 'addToPlayers'
export const SET_UPDATED_PLAYER = 'setUpdatedPlayer'
export const REMOVE_FROM_TEAM_PLAYERS = 'removeFromTeamPlayers'
export const ADD_TO_TRAINERS = 'addToTrainers'
export const SET_UPDATED_TRAINER = 'setUpdatedTrainer'
export const REMOVE_FROM_TRAINERS = 'removeFromTrainers'
export const SET_TEAM_AVERAGES = 'setTeamAverages'

// FOR ORGANISATIONS
export const SET_ORGANISATIONS = 'setOrganisations'
export const SET_LITE_ORGANISATIONS = 'setLiteOrganisations'
export const SET_SELECTED_ORGANISATION = 'setSelectedOrganisation'
export const SET_ORG_UNASSIGNED = 'setOrgUnassigned'
export const SET_ORG_USERS = 'setOrgUsers'
export const SET_ORG_PLAYERS = 'setOrgPlayers'
export const SET_ORG_TRAINERS = 'setOrgTrainers'
export const SET_ORG_ADMINS = 'setOrgAdmins'
export const PURGE_ORGANISATIONS = 'purgeOrganisations'
export const ADD_NEW_ORGANISATION = 'addNewOrganisation'
export const SET_UPDATED_ORGANISATION = 'setUpdatedOrganisation'
export const REMOVE_ORGANISATION = 'removeOrganisation'
export const ADD_USER_TO_UNASSIGNED = 'addUserToUnassigned'
export const REMOVE_USER_FROM_UNASSIGNED = 'removeUserFromUnassigned'
export const ADD_TO_ORG_PLAYERS = 'addToOrgPlayers'
export const REMOVE_FROM_ORG_PLAYERS = 'removeFromOrgPlayers'

// FOR CUSTOM THEMES
export const SET_CUSTOM_THEME = 'setCustomTheme'

// FOR LICENSES
export const SET_TEMPLATES = 'setTemplates'
export const SET_TEMPLATE = 'setTemplate'
export const ADD_TEMPLATE = 'addTemplate'
export const SET_ACTIVE_LICENSE = 'setActiveLicense'
export const SET_UPDATED_LICENSE = 'setUpdatedLicense'

// FOR REPORTS
export const SET_REPORTS = 'setReports'
export const ADD_REPORT = 'addReports'
export const REMOVE_REPORT = 'removeReport'
export const SET_REPORT_SKILLGAMES = 'setReportSkillgames'
export const SET_REPORT_DATES = 'setReportDates'
export const SET_REPORT_PLAYERS = 'setReportPlayers'

// FOR PRINTING CARDS
export const SET_SELECTED_PLAYERS_FOR_PRINT = 'setSelectedPlayersForPrint'
export const SET_ENABLED_BLEED_DOTS = 'setEnabledBleedDots'
export const SET_IMAGE_WITHOUT_CORS = 'setImageWithoutCors'
export const SET_PRINTING_ACTIVE_COLOR = 'setPrintingActiveColor'
export const SET_PRINTING_MAIN_COLOR = 'setPrintingMainColor'
export const SET_PRINTING_LOGO_IMAGE = 'setPrintingLogoImage'
export const SET_PRINTING_SECONDARY_COLOR = 'setPrintingSecondaryColor'
export const SET_PRINTING_PLAYERS = 'setPrintingPlayers'

// FOR PLAYERS
export const SET_SMALL_CARD = 'setSmallCard'
export const SET_SPIDERWEB_VIEW = 'setSpiderWebView'

// FOR PLAYER COMPARISON
export const SET_FIRST_COMPARISON_PLAYER = 'setFirstComparisonPlayer'
export const SET_SECOND_COMPARISON_PLAYER = 'setSecondComparisonPlayer'
export const SET_EXAMPLE_RESULTS = 'setExampleResults'
